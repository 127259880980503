<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>切换门店：</p>
          <select v-model="form.fenpeimendianId" @change="getData(1)">
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>操作时间：</p>
          <input type="date" @change="getData(1)" v-model="form.startDate"><span> &nbsp;-&nbsp; </span>
          <input type="date" @change="getData(1)" v-model="form.endDate">
        </li>
        <li>
          <p>快捷查询：</p>
          <select v-model="Sdate" @change="addDate">
            <option value="0">全部</option>
            <option value="D">今日</option>
            <option value="W">本周</option>
            <option value="M">本月</option>
            <option value="NM">上月</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <colgroup>

        </colgroup>
        <thead>
          <tr>
            <td>盘点时间</td>
            <td>盘点人</td>
            <td>盘点内容</td>
            <td>盘点备注</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{dataFormat(item.pandianshijian)}}</td>
            <td>{{item.pandianrenName}}</td>
            <td>{{item.neirong}}</td>
            <td>{{item.info}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'inventoryInRecord',
  data() {
    return {
      dataList:[],
      storeList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        startDate:'',
        endDate:'',
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      Sdate:0,
    }
  },
  created() {
    this.getStore()
    this.getData(1)
  },
  components: {},
  mounted() {},
  methods: {
    addDate(){
      let date =this.Sdate
      let s= moment(), e= moment()
      if(date==0){
        s=''
        e=''
      }
      else if(date=="NM"){
        s=s.subtract(1,"M").startOf('M').format("YYYY-MM-DD")
        e=e.subtract(1,"M").endOf('M').format("YYYY-MM-DD")
      }else{
         s= s.startOf(date).format("YYYY-MM-DD")
         e= e.endOf(date).format("YYYY-MM-DD")
      }
      this.form.startDate=s
      this.form.endDate=e
      this.getData(1)
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    dataFormat(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnLBClass(lb){
      if(lb==1){
        return 'suc'
      }
      else if(lb==2){
        return 'cal'
      }
      else if(lb==3){
        return 'err'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '新增'
      }
      else if(lb==2){
        return '修改'
      }
      else if(lb==3){
        return '删除'
      }
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/record/checkRecord',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }
      }).catch(() => {
        this.$Mesg({
          content: '获取数据失败！',
          errOrSuc: 'err',
        })
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    
  },
}
</script>
